@import './media';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  height: 100%;
  width: 100%;
}

body {
  transition: background-color 1s ease;
  width: 100%;
  position: relative;
  height: 100%;
}

.wrapper {
  // padding: 1rem 2rem;
  padding: 1rem 1rem;
  width: 100%;
  margin: 0 auto;

  @include Pads() {
    padding: 1rem 4rem;
    max-width: 1000px;
  }

  @include Laptops() {
    width: 1200px;
  }
}

.main-head {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 40px;
  color: #1c1d1f;
  padding: 2rem 0;
}

.main-moto {
  width: 100%;
  color: #1c1d1f;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.h2 {
  font-size: 28px;
  color: #1c1d1f;
  letter-spacing: -1px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.text {
  font-size: 16px;
  color: rgba(28, 29, 31, 0.8);
  font-weight: 200;
  letter-spacing: -0.5px;
  line-height: 22px;
}

.white-text {
  color: #fff !important;
}

.space {
  @include Pads() {
    height: fit-content;
    display: grid;
    place-content: center;
    margin-bottom: 10rem;
  }

  @include Laptops() {
    height: 100vh;
    display: grid;
    place-content: center;
    margin-bottom: 10rem;
  }
}

@import './../components/Nav/nav';
@import './../components/Footer/footer';

@import './../components/Landing/landing';
@import './../components/HeadingSection/headingsection';
@import './../components/Project/project';
@import './../components/Process/process';

@import './../components/ProjectL/projectl';
@import './../components/ProjectB/projectb';
@import './../components/ProjectO/projecto';
@import './../components/ProjectI/projecti';

@import './../components/Models/models';
@import './../components/Overlay/overlay';
@import './../components/Menu/menu';
@import './../components/Menusmall/menusmall';

// .modelsWrapper {
//   height: 100vh;
//   scroll-snap-type: y mandatory;
//   -webkit-scroll-snap-type: y mandatory;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   z-index: 1000;
// }

// .overlaysRoot {
//   position: sticky;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

// .modelOverlay {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   position: sticky;
//   top: 0;

//   height: 100vh;
//   margin-top: -100vh;
// }

// .modelSection {
//   padding: 0rem 1rem;
//   height: 100vh;
//   scroll-snap-align: start;
// }

// .modelSection:nth-child(1) {
//   background-color: rgba(255, 242, 0, 0.05);
// }

// .modelSection:nth-child(2) {
//   background-color: rgba(214, 231, 246, 0.45);
// }

// .modelSection:nth-child(3) {
//   background-color: rrgba(253, 235, 218, 0.45);
// }

// .modelSection:nth-child(4) {
//   background-color: #000;
// }

// .modelSection:nth-child(5) {
//   background-color: rgba(87, 33, 33, 0.369);
// }
