.menu {
  position: fixed;
  top: 1rem;
  // height: 70vh;
  // width: 80vw;
  padding: 2rem 2rem;
  border-radius: 25px;
  right: 1rem;
  background-color: #fff;
  z-index: 111;

  flex-direction: column;
  justify-content: center;
  display: none;

  @include Pads() {
    // width: 30vw;
    display: flex;
  }

  @include Laptops() {
    right: 3rem;
    display: flex;
  }

  .services-subhead {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 1.48;
    letter-spacing: 0.5px;
    font-weight: 400;
  }

  .services-container {
    .services-box {
      display: flex;
      margin: 0.5rem 0;
      gap: 0.5rem 1rem;

      span {
        display: block;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
}

.services-subhead {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.48;
  letter-spacing: 0.5px;
  font-weight: 400;

  @include Laptops() {
    font-size: 12px;
  }
}

.services-container {
  .services-box {
    display: flex;
    margin: 0.5rem 0;
    gap: 0.5rem 1rem;

    span {
      display: block;
      border-radius: 15px;
      font-size: 16px;
      font-weight: 200;

      @include Laptops() {
        font-size: 14px;
      }
    }
  }
}
