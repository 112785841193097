.projecti {
  margin-top: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-bottom: 5rem;

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .next-img {
    display: block;
    margin: 0 auto;
  }
}
