@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  height: 100%;
  width: 100%;
}

body {
  transition: background-color 1s ease;
  width: 100%;
  position: relative;
  height: 100%;
}

.wrapper {
  padding: 1rem 1rem;
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .wrapper {
    padding: 1rem 4rem;
    max-width: 1000px;
  }
}
@media (min-width: 1280px) {
  .wrapper {
    width: 1200px;
  }
}

.main-head {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 40px;
  color: #1c1d1f;
  padding: 2rem 0;
}

.main-moto {
  width: 100%;
  color: #1c1d1f;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.h2 {
  font-size: 28px;
  color: #1c1d1f;
  letter-spacing: -1px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.text {
  font-size: 16px;
  color: rgba(28, 29, 31, 0.8);
  font-weight: 200;
  letter-spacing: -0.5px;
  line-height: 22px;
}

.white-text {
  color: #fff !important;
}

@media (min-width: 735px) and (max-width: 1279px) {
  .space {
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    place-content: center;
    margin-bottom: 10rem;
  }
}
@media (min-width: 1280px) {
  .space {
    height: 100vh;
    display: grid;
    place-content: center;
    margin-bottom: 10rem;
  }
}

nav {
  position: fixed;
  top: 0rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 112;
}
nav .nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
}
@media (min-width: 1280px) {
  nav .nav-container {
    padding: 2rem 4rem;
  }
}
nav .nav-left h1 {
  font-size: 20px;
  color: #282828;
}
@media (min-width: 735px) and (max-width: 1279px) {
  nav .nav-left h1 {
    font-size: 26px;
  }
}
@media (min-width: 1280px) {
  nav .nav-left h1 {
    font-size: 26px;
  }
}
nav .nav-left h1 span {
  font-size: 12px;
  font-weight: 300;
}
@media (min-width: 735px) and (max-width: 1279px) {
  nav .nav-left h1 span {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  nav .nav-left h1 span {
    font-size: 14px;
  }
}
nav .nav-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .nav-right .hamburger-react,
nav .nav-right .hamburger-react * {
  outline: none;
  box-shadow: none;
}
nav .nav-right span {
  font-size: 18px;
  outline: none;
  display: none;
}
nav .nav-content {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  height: 80%;
  place-content: center;
}
@media (min-width: 1280px) {
  nav .nav-content {
    grid-template-columns: repeat(3, 35%);
    place-content: unset;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 5rem;
  }
}
nav .nav-content .content {
  color: #282828;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
nav .nav-content .content .s-title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: 300;
  letter-spacing: -0.2px;
}
@media (min-width: 735px) and (max-width: 1279px) {
  nav .nav-content .content .s-title {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  nav .nav-content .content .s-title {
    font-size: 18px;
  }
}
nav .nav-content .content .li {
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
}
nav .nav-content .content .li a {
  text-decoration: none;
  color: inherit;
}
@media (min-width: 735px) and (max-width: 1279px) {
  nav .nav-content .content .li {
    font-size: 20px;
  }
}
@media (min-width: 1280px) {
  nav .nav-content .content .li {
    font-size: 28px;
  }
}
@media (min-width: 1280px) {
  nav .nav-content .s-mg {
    margin-top: -7.8rem;
  }
}

footer {
  width: 100%;
  position: relative;
  padding-top: 4rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  footer {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  footer {
    margin-top: 15rem;
  }
}
footer .link a {
  text-decoration: none;
  color: inherit;
}
footer .services-subhead {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.48;
  letter-spacing: 0.5px;
  font-weight: 300;
  margin: 3rem 0 2rem;
  display: block;
}
@media (min-width: 735px) and (max-width: 1279px) {
  footer .services-subhead {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  footer .services-subhead {
    font-size: 18px;
  }
}
footer .services-container {
  margin-bottom: 3rem;
}
footer .services-container .services-box {
  display: flex;
  margin: 0.5rem 0;
  gap: 0.8rem;
  flex-wrap: wrap;
}
footer .services-container .services-box span {
  display: block;
  font-size: 11px;
  font-weight: 400;
}
@media (min-width: 735px) and (max-width: 1279px) {
  footer .services-container .services-box span {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  footer .services-container .services-box span {
    font-size: 14px;
  }
}
footer .main-btn {
  border: none;
  background-color: #1c1d1f;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
footer h1 {
  font-size: 36px;
  padding: 2rem 0;
  cursor: pointer;
  margin: 0 auto;
  letter-spacing: -2px;
  font-weight: 500;
  transition: all 0.5s ease;
}
@media (min-width: 1280px) {
  footer h1 {
    font-size: 50px;
  }
}
footer p {
  font-size: 16px;
  padding: 2rem 0;
  letter-spacing: -0.3px;
  font-weight: 300;
}
@media (min-width: 735px) and (max-width: 1279px) {
  footer p {
    width: 500px;
  }
}
@media (min-width: 1280px) {
  footer p {
    width: 500px;
    font-size: 18px;
  }
}
footer .socials {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
}
footer .socials span {
  margin-right: 1.5rem;
}
footer .linear-anim {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(90deg, #fff, rgb(248, 163, 83), rgb(95, 173, 242), #fff, rgb(236, 102, 93));
  background-size: 400% 400%;
  animation: animeGradients 10s infinite linear;
}
@keyframes animeGradients {
  0% {
    background-position: 0 85%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0 85%;
  }
}
footer .credits span {
  font-weight: 400;
}
footer .credits h4 {
  font-size: 12px;
  font-weight: 200;
}
@media (min-width: 1280px) {
  footer .credits h4 {
    font-size: 14px;
  }
}

.main-btn {
  border: none;
  background-color: #1c1d1f;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.landing {
  height: 700px;
  display: flex;
  align-items: center;
}
@media (min-width: 1280px) {
  .landing {
    margin-top: 3rem;
  }
}
.landing .main-head {
  text-align: left;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 40px;
  color: #1c1d1f;
  padding: 2rem 0;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .landing .main-head {
    font-size: 52px;
    line-height: 60px;
    width: 100%;
    max-width: 1000px;
  }
}
@media (min-width: 1280px) {
  .landing .main-head {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -3px;
  }
}
.landing .main-moto {
  width: 100%;
  color: #1c1d1f;
  display: inline-block;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  margin-bottom: 3rem;
  display: block;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .landing .main-moto {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  .landing .main-moto {
    font-size: 18px;
    max-width: 700px;
  }
}
.landing .main-btn {
  border: none;
  background-color: #1c1d1f;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.heading-section {
  color: #1c1d1f;
}
.heading-section h2 {
  font-size: 30px;
  color: #1c1d1f;
  letter-spacing: -1px;
  font-weight: 700;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .heading-section h2 {
    font-size: 35px;
  }
}
@media (min-width: 1280px) {
  .heading-section h2 {
    font-size: 38px;
  }
}
.heading-section p {
  font-size: 16px;
  color: rgba(29, 30, 32, 0.7);
  font-weight: 200;
  line-height: 25px;
  letter-spacing: -0.2px;
  width: 100%;
  margin: 1.5rem 0;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .heading-section p {
    width: 80%;
    max-width: 400px;
    font-size: 18px;
    line-height: 27px;
  }
}
@media (min-width: 1280px) {
  .heading-section p {
    width: 600px;
    font-size: 18px;
    line-height: 27px;
  }
}
.heading-section .webflow-text {
  background: -webkit-linear-gradient(45deg, #ebebeb, #4a6ada, #4a6ada);
  width: -moz-fit-content;
  width: fit-content;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project {
    flex-direction: row;
    height: 100vh;
    margin: 0rem auto;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .project {
    flex-direction: row;
    height: 100vh;
    margin: 0rem auto;
    justify-content: space-between;
  }
}
.project .img {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 300px;
  margin-bottom: 2rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project .img {
    margin-bottom: unset;
    width: 48%;
    height: 400px;
  }
}
@media (min-width: 1280px) {
  .project .img {
    margin-bottom: unset;
    width: 600px;
    height: 600px;
  }
}
.project .info {
  width: 100%;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project .info {
    width: 48%;
  }
}
@media (min-width: 1280px) {
  .project .info {
    width: 500px;
  }
}
.project .info h4 {
  color: rgba(28, 29, 31, 0.897);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.8px;
}
@media (min-width: 1280px) {
  .project .info h4 {
    font-size: 18px;
  }
}
.project .info h2 {
  font-size: 22px;
  font-weight: 500;
  color: #1c1d1f;
  margin: 1rem 0;
  letter-spacing: -1px;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project .info h2 {
    font-size: 28px;
    letter-spacing: -1.5px;
  }
}
@media (min-width: 1280px) {
  .project .info h2 {
    font-size: 34px;
    letter-spacing: -1.5px;
  }
}
.project .info h6 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #1c1d1f;
  display: inline-block;
  cursor: pointer;
}

.project-reverse {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex-direction: column;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .project-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.project-review-container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: 5rem auto;
}
@media (min-width: 1280px) {
  .project-review-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem 9rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(2) {
    margin-top: 25rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(3) {
    margin-top: -10rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(4) {
    margin-top: 10rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(5) {
    margin-top: -5rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(6) {
    margin-top: 15rem;
  }
}
@media (min-width: 1280px) {
  .project-review-container .project-review:nth-child(7) {
    margin-top: -5rem;
  }
}

.project-review {
  cursor: pointer;
  transition: all 1s ease;
}
@media (min-width: 1280px) {
  .project-review {
    width: 500px;
  }
}
.project-review .pr-img {
  height: 400px;
  width: 100%;
  border-radius: 15px;
  position: relative;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project-review .pr-img {
    height: 600px;
  }
}
@media (min-width: 1280px) {
  .project-review .pr-img {
    height: 500px;
  }
}
.project-review .pr-img img {
  border-radius: 15px;
}
.project-review .pr-info {
  margin-top: 2rem;
  width: 100%;
}
.project-review .pr-info h2 {
  margin: 1rem 0;
  font-weight: 400;
  font-size: 22px;
  color: #1c1d1f;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .project-review .pr-info h2 {
    font-size: 32px;
  }
}
@media (min-width: 1280px) {
  .project-review .pr-info h2 {
    font-size: 32px;
  }
}
.project-review .pr-info p {
  font-size: 16px;
  font-weight: 300;
  color: rgba(28, 29, 31, 0.7137254902);
}
@media (min-width: 1280px) {
  .project-review .pr-info p {
    font-size: 18px;
  }
}
.project-review .pr-info button {
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: #1c1d1f;
  color: #fff;
  border-radius: 5px;
}

.process {
  color: #000;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .process {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 5rem;
    justify-content: space-between;
    margin: 0rem auto;
  }
}
@media (min-width: 1280px) {
  .process {
    display: grid;
    grid-template-columns: repeat(4, 20%);
    grid-gap: 5rem;
    justify-content: space-between;
    margin: 0rem auto;
  }
}
.process .process-container {
  margin: 4rem 0;
}
.process .process-container span {
  font-size: 120px;
  font-weight: 200;
  letter-spacing: -7px;
}
.process .process-container h4 {
  font-size: 30px;
  font-weight: 500;
}
.process .process-container p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 200;
  margin-top: 1rem;
  letter-spacing: -0.2px;
}

.vertical-process {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 7.5rem;
}
@media (min-width: 1280px) {
  .vertical-process {
    flex-direction: row;
    margin-top: 15rem;
  }
}
.vertical-process .vertical-process-line {
  width: 100%;
  padding: 0 1rem;
}
@media (min-width: 1280px) {
  .vertical-process .vertical-process-line {
    width: 500px;
    padding: unset;
  }
}
.vertical-process .vertical-process-line .vertical-item {
  border-left: 1px solid #fff;
  padding: 4rem 2rem;
  position: relative;
}
.vertical-process .vertical-process-line .vertical-item h4 {
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #fff;
}
.vertical-process .vertical-process-line .vertical-item p {
  font-size: 0.85rem;
  color: rgb(255, 255, 255);
  line-height: 1.4rem;
  font-weight: 200;
}
.vertical-process .vertical-process-line .vertical-item .vertical-span {
  top: 0;
  position: absolute;
  left: 2rem;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.515);
}
.vertical-process .vertical-process-line .vertical-item .vertical-step {
  border: 1px solid #fff;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  top: 0;
  left: -0.8rem;
  display: grid;
  place-content: center;
  z-index: 10;
  transition: all 1s ease;
}
.vertical-process .vertical-process-line .vertical-item .vertical-step .dot {
  background-color: #fff;
  height: 11px;
  width: 11px;
  border-radius: 50%;
}
.vertical-process .vertical-process-desc {
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;
}
@media (min-width: 1280px) {
  .vertical-process .vertical-process-desc {
    width: 500px;
    position: sticky;
    top: 35%;
    margin-bottom: 0rem;
  }
}
.vertical-process .vertical-process-desc h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -2px;
  margin-bottom: 2rem;
  color: #fff;
}
@media (min-width: 1280px) {
  .vertical-process .vertical-process-desc h2 {
    font-size: 3rem;
  }
}
.vertical-process .vertical-process-desc p {
  font-size: 1rem;
  line-height: 1.8;
  color: rgb(255, 255, 255);
  font-weight: 200;
}
.vertical-process .vertical-process-desc .vertical-process-desc-bot {
  margin-top: 1rem;
}

.projectl {
  margin-top: 8rem;
  display: grid;
  place-content: center;
  color: #1c1d1f;
  margin-bottom: 4rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectl {
    display: flex;
    justify-content: space-between;
    margin-top: 12rem;
  }
}
@media (min-width: 1280px) {
  .projectl {
    display: flex;
    justify-content: space-between;
    margin-top: 12rem;
  }
}
.projectl .list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-bottom: 3rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectl .list {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .projectl .list {
    width: 50%;
  }
}
.projectl .list .list-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.projectl .list .list-content .s-title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: 300;
  letter-spacing: -0.2px;
}
.projectl .list .list-content .li {
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectl .intro {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .projectl .intro {
    width: 50%;
  }
}
.projectl .intro h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  letter-spacing: -0.3px;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectl .intro h2 {
    font-size: 2rem;
    letter-spacing: -0.5px;
    line-height: 30px;
  }
}
@media (min-width: 1280px) {
  .projectl .intro h2 {
    font-size: 2.2rem;
    letter-spacing: -0.5px;
    line-height: 42px;
  }
}
.projectl .intro p {
  font-size: 12px;
  font-weight: 300;
  color: rgba(28, 29, 31, 0.8);
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectl .intro p {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  .projectl .intro p {
    font-size: 18px;
  }
}

.projectb {
  height: 400px;
  position: relative;
  width: 100%;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projectb {
    height: 600px;
  }
}
@media (min-width: 1280px) {
  .projectb {
    height: 600px;
  }
}

.projecto {
  margin-top: 8rem;
  color: #1c1d1f;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projecto {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .projecto {
    display: flex;
    margin-top: 16rem;
    margin-bottom: 14rem;
  }
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projecto .projecto-desc {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .projecto .projecto-desc {
    width: 50%;
  }
}
.projecto h3 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-bottom: 2rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projecto h3 {
    width: 50%;
    font-size: 26px;
    letter-spacing: -0.7px;
  }
}
@media (min-width: 1280px) {
  .projecto h3 {
    width: 50%;
    font-size: 38px;
    letter-spacing: -1px;
  }
}
.projecto p {
  font-size: 14px;
  font-weight: 300;
  color: rgba(28, 29, 31, 0.8);
}
@media (min-width: 735px) and (max-width: 1279px) {
  .projecto p {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  .projecto p {
    font-size: 18px;
  }
}
.projecto .btn {
  margin-top: 1.5rem;
  display: inline-block;
  font-size: 14px;
  color: #1c1d1f;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.4s ease;
}
.projecto .btn a {
  text-decoration: none;
  color: inherit;
}
@media (min-width: 1280px) {
  .projecto .btn {
    font-size: 16px;
  }
}
.projecto .btn:hover {
  color: #000;
}

.projecti {
  margin-top: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-bottom: 5rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projecti .next-img {
  display: block;
  margin: 0 auto;
}

.defaultOverlayContent {
  display: flex;
  justify-content: space-around;
  flex: 1;
  width: 100vw;
  align-items: center;
  height: 100vh;
}
.defaultOverlayContent .info {
  width: 100%;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .defaultOverlayContent .info {
    width: 48%;
  }
}
@media (min-width: 1280px) {
  .defaultOverlayContent .info {
    width: 500px;
  }
}
.defaultOverlayContent .info h4 {
  color: rgba(28, 29, 31, 0.897);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.8px;
}
@media (min-width: 1280px) {
  .defaultOverlayContent .info h4 {
    font-size: 18px;
  }
}
.defaultOverlayContent .info h2 {
  font-size: 22px;
  font-weight: 500;
  color: #1c1d1f;
  margin: 1rem 0;
  letter-spacing: -1px;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .defaultOverlayContent .info h2 {
    font-size: 28px;
    letter-spacing: -1.5px;
  }
}
@media (min-width: 1280px) {
  .defaultOverlayContent .info h2 {
    font-size: 34px;
    letter-spacing: -1.5px;
  }
}
.defaultOverlayContent .info h6 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #1c1d1f;
  display: inline-block;
  cursor: pointer;
}
.defaultOverlayContent .img {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 300px;
  margin-bottom: 2rem;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .defaultOverlayContent .img {
    margin-bottom: unset;
    width: 48%;
    height: 400px;
  }
}
@media (min-width: 1280px) {
  .defaultOverlayContent .img {
    margin-bottom: unset;
    width: 600px;
    height: 600px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(28, 27, 27, 0.1607843137);
}

.menu {
  position: fixed;
  top: 1rem;
  padding: 2rem 2rem;
  border-radius: 25px;
  right: 1rem;
  background-color: #fff;
  z-index: 111;
  flex-direction: column;
  justify-content: center;
  display: none;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .menu {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .menu {
    right: 3rem;
    display: flex;
  }
}
.menu .services-subhead {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.48;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.menu .services-container .services-box {
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem 1rem;
}
.menu .services-container .services-box span {
  display: block;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 200;
}

.services-subhead {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 1.48;
  letter-spacing: 0.5px;
  font-weight: 400;
}
@media (min-width: 1280px) {
  .services-subhead {
    font-size: 12px;
  }
}

.services-container .services-box {
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem 1rem;
}
.services-container .services-box span {
  display: block;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 200;
}
@media (min-width: 1280px) {
  .services-container .services-box span {
    font-size: 14px;
  }
}

.menusmall {
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 111;
  display: block;
  padding: 0 1rem;
  display: grid;
  place-content: center;
}
@media (min-width: 735px) and (max-width: 1279px) {
  .menusmall {
    display: none !important;
  }
}
@media (min-width: 1280px) {
  .menusmall {
    display: none !important;
  }
}
.menusmall .menusmallContent {
  padding: 6rem 1rem;
}
.menusmall .menusmallContent .content {
  margin: 2rem 0;
}
.menusmall .menusmallContent h5 {
  font-weight: 200;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.menusmall .menusmallContent .contentItem {
  font-size: 1.2rem;
  list-style-type: none;
  margin-top: 0.5rem;
  font-weight: 500;
}

.menusmall-headings h2 {
  font-size: 2rem;
}
.menusmall-headings p {
  font-size: 1rem;
}/*# sourceMappingURL=global.css.map */