nav {
  position: fixed;
  top: 0rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  // blur
  // backdrop-filter: blur(15px);
  // background-color: rgba(0, 0, 0, 0.3);
  // background-color: rgba(255, 255, 255, 0.9);
  z-index: 112;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // sve sto sam uradio je dodao padding jer sam sklonio wrapper
    padding: 2rem 1rem;

    @include Laptops() {
      padding: 2rem 4rem;
    }
  }

  .nav-left {
    h1 {
      font-size: 20px;
      // color: #fff;
      color: #282828;

      @include Pads() {
        font-size: 26px;
      }

      @include Laptops() {
        font-size: 26px;
      }

      span {
        // color: #000;
        // margin-left: 1rem;
        font-size: 12px;
        font-weight: 300;

        @include Pads() {
          font-size: 14px;
        }

        @include Laptops() {
          font-size: 14px;
        }
      }
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    justify-content: center;

    .hamburger-react,
    .hamburger-react * {
      outline: none;
      box-shadow: none;
    }

    span {
      // margin-right: 0.5rem;
      font-size: 18px;
      outline: none;
      display: none;
    }
  }

  .nav-content {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    height: 80%;
    place-content: center;

    @include Laptops() {
      grid-template-columns: repeat(3, 35%);
      place-content: unset;

      height: fit-content;
      margin-top: 5rem;
    }

    .content {
      // color: #fff;
      color: #282828;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      .s-title {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 1rem;
        font-weight: 300;
        letter-spacing: -0.2px;

        @include Pads() {
          font-size: 18px;
        }

        @include Laptops() {
          font-size: 18px;
        }
      }

      .li {
        margin-bottom: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.2px;

        a {
          text-decoration: none;
          color: inherit;
        }

        @include Pads() {
          font-size: 20px;
        }

        @include Laptops() {
          font-size: 28px;
        }
      }
    }

    .s-mg {
      @include Laptops() {
        margin-top: -7.8rem;
      }
    }
  }
}
