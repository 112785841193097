footer {
  width: 100%;
  position: relative;

  // margin-top: 15rem;
  padding-top: 4rem;

  @include Pads() {
    margin-top: 0;
  }

  @include Laptops() {
    margin-top: 15rem;
  }

  .link {
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .services-subhead {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1.48;
    letter-spacing: 0.5px;
    font-weight: 300;
    margin: 3rem 0 2rem;
    display: block;

    @include Pads() {
      font-size: 18px;
    }

    @include Laptops() {
      font-size: 18px;
    }
  }

  .services-container {
    margin-bottom: 3rem;
    .services-box {
      display: flex;
      margin: 0.5rem 0;
      gap: 0.8rem;
      flex-wrap: wrap;

      span {
        display: block;
        font-size: 11px;
        font-weight: 400;

        @include Pads() {
          font-size: 14px;
        }

        @include Laptops() {
          font-size: 14px;
        }
      }
    }
  }

  .main-btn {
    border: none;
    background-color: #1c1d1f;

    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }

  h1 {
    font-size: 36px;
    padding: 2rem 0;
    cursor: pointer;
    margin: 0 auto;
    letter-spacing: -2px;
    font-weight: 500;
    transition: all 0.5s ease;

    @include Laptops() {
      font-size: 50px;
    }
  }

  // .hor-line {
  //   border: 0.2px solid #fff;
  //   width: 680px;
  //   transition: all 0.5s ease;
  // }

  // h1:hover ~ .hor-line {
  //   width: 0px;
  //   border: 0.2px solid #000;
  // }

  p {
    font-size: 16px;
    padding: 2rem 0;
    letter-spacing: -0.3px;
    font-weight: 300;

    @include Pads() {
      width: 500px;
    }

    @include Laptops() {
      width: 500px;
      font-size: 18px;
    }
  }

  .socials {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    margin-top: 2rem;
    span {
      margin-right: 1.5rem;
    }
  }

  .linear-anim {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      90deg,
      #fff,
      rgb(248, 163, 83),
      rgb(95, 173, 242),
      #fff,
      rgb(236, 102, 93)
    );
    background-size: 400% 400%;
    animation: animeGradients 10s infinite linear;
  }

  @keyframes animeGradients {
    0% {
      background-position: 0 85%;
    }
    50% {
      background-position: 100% 20%;
    }
    100% {
      background-position: 0 85%;
    }
  }

  .credits {
    // position: absolute;
    // bottom: 1rem;
    span {
      font-weight: 400;
    }

    h4 {
      font-size: 12px;
      font-weight: 200;

      @include Laptops() {
        font-size: 14px;
      }
    }
  }
}

.main-btn {
  border: none;
  background-color: #1c1d1f;

  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
