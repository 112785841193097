.project {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  flex-direction: column;
  // margin: 5rem 0;

  @include Pads() {
    flex-direction: row;
    height: 100vh;
    margin: 0rem auto;
    justify-content: space-between;
  }

  @include Laptops() {
    flex-direction: row;
    height: 100vh;
    margin: 0rem auto;
    justify-content: space-between;
  }

  .img {
    position: relative;
    // desktop
    // width: 600px;
    // height: 600px;
    cursor: pointer;

    width: 100%;
    height: 300px;
    margin-bottom: 2rem;

    @include Pads() {
      margin-bottom: unset;
      width: 48%;
      height: 400px;
    }

    @include Laptops() {
      margin-bottom: unset;
      width: 600px;
      height: 600px;
    }
  }

  .info {
    // desktop
    // width: 500px;

    width: 100%;

    @include Pads() {
      width: 48%;
    }

    @include Laptops() {
      width: 500px;
    }

    h4 {
      color: rgba(28, 29, 31, 0.897);
      // font-size:20px;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.8px;

      @include Laptops() {
        font-size: 18px;
      }
    }

    h2 {
      // font-size: 50px;
      font-size: 22px;
      font-weight: 500;
      color: #1c1d1f;
      margin: 1rem 0;
      letter-spacing: -1px;

      @include Pads() {
        font-size: 28px;
        letter-spacing: -1.5px;
      }

      @include Laptops() {
        font-size: 34px;
        letter-spacing: -1.5px;
      }
    }

    h6 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.2px;
      color: #1c1d1f;

      display: inline-block;
      cursor: pointer;
    }
  }
}

.project-reverse {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  flex-direction: column;

  @include Pads() {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @include Laptops() {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

// NEW
.project-review-container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin: 5rem auto;

  @include Laptops() {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 0rem 9rem;
  }

  .project-review:nth-child(2) {
    @include Laptops() {
      margin-top: 25rem;
    }
  }

  .project-review:nth-child(3) {
    @include Laptops() {
      margin-top: -10rem;
    }
  }

  .project-review:nth-child(4) {
    @include Laptops() {
      margin-top: 10rem;
    }
  }

  .project-review:nth-child(5) {
    @include Laptops() {
      margin-top: -5rem;
    }
  }

  .project-review:nth-child(6) {
    @include Laptops() {
      margin-top: 15rem;
    }
  }

  .project-review:nth-child(7) {
    @include Laptops() {
      margin-top: -5rem;
    }
  }
}
.project-review {
  cursor: pointer;
  transition: all 1s ease;

  @include Laptops() {
    width: 500px;
  }

  .pr-img {
    height: 400px;
    width: 100%;
    // background-color: rgba(255, 242, 0, 0.05);
    border-radius: 15px;
    position: relative;

    @include Pads() {
      height: 600px;
    }

    @include Laptops() {
      height: 500px;
    }

    img {
      border-radius: 15px;
    }
  }

  .pr-info {
    margin-top: 2rem;
    width: 100%;

    h2 {
      margin: 1rem 0;
      font-weight: 400;
      font-size: 22px;
      color: #1c1d1f;

      @include Pads() {
        font-size: 32px;
      }

      @include Laptops() {
        font-size: 32px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      color: #1c1d1fb6;

      @include Laptops() {
        font-size: 18px;
      }
    }

    button {
      padding: 0.5rem 1rem;
      margin: 1rem 0;
      background-color: #1c1d1f;
      color: #fff;
      border-radius: 5px;
    }
  }
}
