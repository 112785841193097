.projectl {
  margin-top: 8rem;
  display: grid;
  place-content: center;
  color: #1c1d1f;
  margin-bottom: 4rem;

  @include Pads() {
    display: flex;
    justify-content: space-between;
    margin-top: 12rem;
  }
  @include Laptops() {
    display: flex;
    justify-content: space-between;
    margin-top: 12rem;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-bottom: 3rem;

    @include Pads() {
      width: 50%;
    }

    @include Laptops() {
      width: 50%;
    }

    .list-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      .s-title {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 1rem;
        font-weight: 300;
        letter-spacing: -0.2px;
      }

      .li {
        margin-bottom: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.2px;
      }
    }
  }

  .intro {
    @include Pads() {
      width: 50%;
    }

    @include Laptops() {
      width: 50%;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      font-weight: 600;
      letter-spacing: -0.3px;

      @include Pads() {
        font-size: 2rem;
        letter-spacing: -0.5px;
        line-height: 30px;
      }

      @include Laptops() {
        font-size: 2.2rem;
        letter-spacing: -0.5px;
        line-height: 42px;
      }
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: rgba(28, 29, 31, 0.8);

      @include Pads() {
        font-size: 14px;
      }

      @include Laptops() {
        font-size: 18px;
      }
    }
  }
}
