.process {
  // color: #fff;
  color: #000;

  // border: 1px solid red;

  @include Pads() {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 5rem;
    justify-content: space-between;
    // margin: 5rem auto;
    margin: 0rem auto;
  }

  @include Laptops() {
    display: grid;
    grid-template-columns: repeat(4, 20%);
    grid-gap: 5rem;
    justify-content: space-between;
    // margin: 5rem auto;
    margin: 0rem auto;
  }

  .process-container {
    margin: 4rem 0;

    span {
      font-size: 120px;
      font-weight: 200;
      letter-spacing: -7px;
    }

    h4 {
      font-size: 30px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      // color: rgba(255, 255, 255, 0.85);
      color: rgba(255, 255, 255, 0.85);
      font-weight: 200;
      margin-top: 1rem;
      letter-spacing: -0.2px;
    }
  }
}

.vertical-process {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 7.5rem;

  // border: 1px solid red;

  @include Laptops() {
    flex-direction: row;
    margin-top: 15rem;
    // margin-top: -5rem;
  }

  .vertical-process-line {
    width: 100%;
    padding: 0 1rem;

    @include Laptops() {
      width: 500px;
      padding: unset;
    }

    .vertical-item {
      border-left: 1px solid #fff;
      padding: 4rem 2rem;
      position: relative;

      h4 {
        font-weight: 400;
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #fff;
      }

      p {
        font-size: 0.85rem;
        color: rgba(255, 255, 255);
        line-height: 1.4rem;
        font-weight: 200;
      }

      .vertical-span {
        top: 0;
        position: absolute;
        left: 2rem;
        font-size: 0.85rem;
        color: rgba(255, 255, 255, 0.515);
      }

      .vertical-step {
        border: 1px solid #fff;
        position: absolute;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        top: 0;
        left: -0.8rem;
        display: grid;
        place-content: center;
        z-index: 10;
        transition: all 1s ease;

        .dot {
          background-color: #fff;
          height: 11px;
          width: 11px;
          border-radius: 50%;
        }
      }
    }
  }

  .vertical-process-desc {
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;

    @include Laptops() {
      width: 500px;
      position: sticky;
      top: 35%;
      margin-bottom: 0rem;
    }

    h2 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.15;
      letter-spacing: -2px;
      margin-bottom: 2rem;
      color: #fff;

      @include Laptops() {
        font-size: 3rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.8;
      color: rgba(255, 255, 255);
      font-weight: 200;
    }

    .vertical-process-desc-bot {
      margin-top: 1rem;
    }
  }
}
